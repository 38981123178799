<template>
	<ZyroPopupCard
		class="color-picker"
		:title="$t('builder.colorPicker.selectColor')"
		:is-absolute="isAbsolute"
		:show-footer="showFooter"
		data-qa="popup-colorpicker"
		type="editor"
		@close="onClose"
		@save="$emit('save', color)"
	>
		<ColorPickerSettings
			:value="colorPickerValue"
			class="color-picker__color-selector"
			:disable-alpha="disableAlpha"
			@input="onColorSelect($event)"
		/>
	</ZyroPopupCard>
</template>

<script>
import { Chrome as ColorPickerSettings } from 'vue-color';
import {
	mapActions,
	mapState,
} from 'vuex';

import { COLOR_PICKER_MODE_FULL } from '@/components/global/zyro-color-picker/constants';
import {
	isCustomColor,
	stripColorFromVariable,
} from '@/utils/colorVariableModifiers';

const DEFAULT_COLOR = 'rgb(0, 0, 0)';

export default {
	components: { ColorPickerSettings },
	props: {
		selectedColor: {
			type: String,
			required: true,
		},
		isAbsolute: {
			type: Boolean,
			default: false,
		},
		disableAlpha: {
			type: Boolean,
			default: false,
		},
		showFooter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { color: this.selectedColor };
	},
	computed: {
		...mapState(['website']),
		colorPickerValue() {
			if (!isCustomColor(this.selectedColor)) {
				return this.website.styles.colors[stripColorFromVariable(this.selectedColor)]
					|| DEFAULT_COLOR;
			}

			return this.selectedColor;
		},
	},
	methods: {
		...mapActions('colors', ['addRecentColor']),
		onColorSelect(color) {
			const selectedColor = color.rgba.a === 1 ? color.hex : `rgba(${Object.values(color.rgba).join(', ')})`;

			this.color = selectedColor;
			this.$emit('select-color', selectedColor);
		},
		onClose() {
			this.addRecentColor(this.color);
			this.$emit('change-mode', COLOR_PICKER_MODE_FULL);
		},
	},
};
</script>

<style lang="scss" scoped>
.color-picker {
	padding: 0;

	&__color-selector {
		width: 100%;
		box-shadow: none;
	}
}
</style>
